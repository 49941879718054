.App {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: black;
  overflow: hidden;
}

@font-face {
  font-family: "spaceranger";
  src: local("spaceranger"),
   url("./assets/font/spaceranger.ttf") format("truetype");
  }

  @font-face {
    font-family: "Bebas";
    src: local("Bebas"),
     url("./assets/font/Bebas-Regular.ttf") format("truetype");
    }
  
    @font-face {
      font-family: "Digital Counter 7";
      src: local("Digital Counter 7"),
       url("./assets/font/digital_counter_7.ttf") format("truetype");
      }
    
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0px !important;
  background: black;
}

html{
  --uicolor-blue: rgba(34,148,220,0.8);
  --status-color-0: rgba(0,90,0,0.75);
  --uicolor-selected: rgba(0,200,255,0.9);
  --uicolor-cellborder: rgba(150,150,150,0.5);
  --uicolor-bg:rgba(0,0,0,0.7);
}

div::-webkit-scrollbar-thumb {
      background: #1e82cb;
      border: 1px solid #1e82cb;
      border-radius: 0.5vw;
    }
    
div::-webkit-scrollbar {
  height: min(0.4vw, 0.711vh);
  width: min(0.4vw, 0.711vh);
  background-color: transparent;
}
